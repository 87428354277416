export default (oldArr, nis) => {
  // console.log(oldArr)
  const arr = [...oldArr]
  const NIS = nis.split('').reverse().join('')
  let total = 0
  // const newArr = Array.from(arr, x => 0)
  for (let i = 0; i < NIS.length; i++) {
    total += Number(NIS[i])
  }
  for (let i = arr.length - 1; i >= 0; i--) {
    let temp = null
    // console.log(arr[i])
    const j = Math.floor((i + 1) * (Number(NIS[i % 7]) + arr[i].id) / (total + arr[i].id))
    if (Number(NIS[i % 7]) === 0) {
      const j2 = Math.floor(arr.length * (Number(NIS[i % 7]) + arr[i].id) / (total + arr[i].id))
      temp = arr[i]
      arr[i] = arr[j2]
      arr[j2] = temp
    } else if (Number(NIS[i % 7]) % 2 === 0) {
      temp = arr[i]
      arr[i] = arr[j]
      arr[j] = temp
    } else if (Number(NIS[i % 7]) % 2 !== 0) {
      const j2 = Math.floor((i + 1) * j / arr.length)
      temp = arr[i]
      arr[i] = arr[j2]
      arr[j2] = arr[j]
      arr[j] = temp
    }
  }

  if (total % 2 !== 0) {
    arr.reverse()
  }
  return arr
}
